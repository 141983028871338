
import Vue from "vue";

export default Vue.extend({
  name: "select-atlas-file-category",
  props: {
    categoryOptions: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      atlasFileCategoryOptions: this.categoryOptions,
      selectedCategory: "",
      loading: false
    };
  },
  methods: {
    updateAtlasFile(): void {
      this.$emit("categorySelected", this.selectedCategory);
    },
    setCategory($event: any) {
      this.selectedCategory = $event;
    }
  }
});
